/*
 * @Author: xiaodongyu
 * @Date 2020-10-30 15:40:07
 * @Last Modified by: xiaodongyu
 * @Last Modified time: 2021-12-01 14:14:23
 */
/* stylelint-disable selector-class-pattern */

$color-black: #000;
$color-white: #fff;
$color-grey: #ddd;

@mixin rich {
    pre {
        padding: 0.7rem 1rem;
        border-radius: 5px;
        background: $color-black;
        color: $color-white;
        font-size: 0.8rem;
        overflow-x: auto;

        code {
            display: block;
        }
    }

    p code {
        padding: 0.2rem 0.4rem;
        border-radius: 5px;
        font-size: 0.8rem;
        font-weight: bold;
        background: rgb($color-black, 0.1);
        color: rgb($color-black, 0.8);
    }

    ul,
    ol {
        padding-left: 1rem;
    }

    li > p,
    li > ol,
    li > ul {
        margin: 0;
    }

    a {
        // color: inherit;
    }

    blockquote {
        border-left: 3px solid rgb($color-black, 0.1);
        color: rgb($color-black, 0.8);
        padding-left: 0.8rem;
        font-style: italic;

        p {
            margin: 0;
        }
    }

    img {
        max-width: 100%;
        border-radius: 3px;
    }

    table {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
        margin: 0;
        overflow: hidden;

        td,
        th {
            min-width: 1em;
            border: 2px solid $color-grey;
            padding: 3px 5px;
            vertical-align: top;
            box-sizing: border-box;
            position: relative;

            > * {
                margin-bottom: 0;
            }
        }

        th {
            font-weight: bold;
            text-align: left;
        }

        .selectedCell::after {
            z-index: 2;
            position: absolute;
            content: "";
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: rgb(200 200 255 / 40%);
            pointer-events: none;
        }

        .column-resize-handle {
            position: absolute;
            right: -2px;
            top: 0;
            bottom: 0;
            width: 4px;
            z-index: 20;
            background-color: #adf;
            pointer-events: none;
        }
    }

    .tableWrapper {
        margin: 1em 0;
        overflow-x: auto;
    }
}
