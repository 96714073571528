$primary-color:primary-color;
$primary-font-color:primary-font-color;
$bg-color:bg-color;
$cs-bubble-color:cs-bubble-color;
$cs-font-color:cs-font-color;







































.svg-icon {
    width: 1em;
    height: 1em;
    vertical-align: -.15em;
    fill: currentColor;
    overflow: hidden;
}
